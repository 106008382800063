export default class BaseComponent {
    constructor(elementReference, container = document.body){

        this.element = null
        this.registeredEvents = {}

        this.getElementByReference(elementReference, container)
    }

    getElementByReference(elementReference, container){
        let tempElement

        if (typeof elementReference === 'string') {
            tempElement = container.querySelector(elementReference)
        }
        else {
            tempElement = elementReference
        }

        if (tempElement instanceof HTMLElement) {
            this.setElement(tempElement)
        }
        else {
            console.error('element could not be found. element / selector:', elementReference)
        }
    }

    setElement(element){
        this.element = element
    }

    getChildElementBySelector(selector, container = this.element){
        return container.querySelector(selector)
    }

    emitEvent(name, data){
        const callbackFunctions = this.registeredEvents[name]
        const isEventRegistered = callbackFunctions && callbackFunctions.length > 0

        if (isEventRegistered) {
            callbackFunctions.forEach((callbackFunction) => {
                if (typeof callbackFunction === 'function') {
                    callbackFunction(data)
                }
            })
        }
        else {
            console.warn('emit: no event was found "', name, '"')
        }
    }

    onEvent(name, callback){
        const oldCallbacks = this.registeredEvents[name] || []

        if (oldCallbacks.includes(callback)) {
            console.warn('add: callback was already registered to this event')
        }
        else {
            this.registeredEvents[name] = [...oldCallbacks, callback]
        }
    }

    offEvent(name, callback){
        const callbackFunctions = this.registeredEvents[name]

        if (callbackFunctions && callbackFunctions.length > 0) {
            this.registeredEvents[name] = callbackFunctions.filter((callbackFunction) => callbackFunction !== callback)
        }
        else {
            console.warn('remove: no event were registered')
        }
    }

    destroy(){}
}
