import { throttle, debounce } from 'throttle-debounce'
import BaseComponent from '@bahn-wordpress/common/src/js/blocks/base-component'
import { getMediaQueryByWidth } from '@bahn-wordpress/common/src/js/utils/media-query-helper'
import * as screenSizes from '@common/src/js/utils/screen-sizes'

export default class MainNavigation extends BaseComponent {
    constructor(...args){
        super(...args)

        this.isFirstBlockHeaderGallery = false
        this.hasSubMenu = false
        this.subMenuBackground = null
        this.buttonOpenOverlay = null
        this.closeOverlayButton = null
        this.overlay = null
        this.isOverlayOpened = false
        this.actualMediaQuery = null
        this.subMenuItems = []
        this.subSubMenuItems = []
        this.onScroll = throttle(100, this.onScrollThrottled.bind(this))
        this.onResize = debounce(300, this.onResizeDebounced.bind(this))

        if (this.element) {
            this.initScrollBehaviour()
            this.initBurgerMenu()
            this.findSubMenu()
            this.setSubMenuClass()

            if (this.hasSubMenu) {
                this.initSubMenu()
            }
        }
    }

    get isDesktopMenu(){
        const mq = getMediaQueryByWidth(window.innerWidth)
        return mq !== screenSizes.small && mq !== screenSizes.medium
    }

    findSubMenu(){
        this.hasSubMenu = this.element.querySelectorAll('.main-navigation__page-items > .menu-item-has-children').length > 0
    }

    setSubMenuClass(){
        this.element.classList.add(`main-navigation--sub-menu-${this.hasSubMenu ? 'enabled' : 'disabled'}`)
    }

    initScrollBehaviour(){
        this.findHeaderGallery()

        this.addScrollListener()
        this.addResizeListener()

        // finally
        this.updateAppearance()
        this.enableAnimations()
    }

    initBurgerMenu(){
        this.buttonOpenOverlay = this.getChildElementBySelector('.main-navigation__button-toggle--open')
        this.closeOverlayButton = this.getChildElementBySelector('.main-navigation__close-button')
        this.overlay = this.getChildElementBySelector('.main-navigation__overlay')

        if (this.buttonOpenOverlay) {
            this.buttonOpenOverlay.addEventListener('click', this.openOverlayClicked.bind(this))
        }

        if (this.closeOverlayButton) {
            this.closeOverlayButton.addEventListener('click', this.closeOverlayClicked.bind(this))
        }

        this.overlay.addEventListener('click', (e) => {
            if(this.isOverlayOpened) {
                const href = e.target.getAttribute('href')
                if(href && href.includes('#')) {
                    requestAnimationFrame(() => this.closeOverlayClicked())
                }
            }
        })
    }

    initSubMenu(){
        this.subMenuBackground = this.element.querySelector('.main-navigation__sub-menu-background')
        this.subMenuItems = this.element.querySelectorAll('.main-navigation__page-items > .menu-item-has-children')
        this.subSubMenuItems = this.element.querySelectorAll('.main-navigation__page-items > .menu-item-has-children .menu-item-has-children')

        this.overlay.addEventListener('mouseover', this.onOverlayMouseOver.bind(this))
        this.element.addEventListener('mouseout', this.onNavigationMouseOut.bind(this))

        // todo move?
        const rootMenuItems = this.element.querySelectorAll('.main-navigation__page-items > .menu-item')
        const subMenuItemArrows =  this.element.querySelectorAll('.main-navigation__page-items > .menu-item-has-children > .menu-item__arrow-wrapper')
        const subSubMenuItemArrows =  this.element.querySelectorAll('.main-navigation__page-items > .menu-item-has-children .menu-item-has-children > .menu-item__arrow-wrapper')

        const addClickListenerToSubMenuItemArrows = (arrows, depth = 1) => {
            arrows.forEach((arrow) => {
                arrow.addEventListener('click', (event) => {
                    event.preventDefault()
                    this.onSubMenuArrowClick(arrow.parentElement, depth)
                })
            })
        }

        addClickListenerToSubMenuItemArrows(subMenuItemArrows, 2)
        addClickListenerToSubMenuItemArrows(subSubMenuItemArrows, 3)


        rootMenuItems.forEach(item => {
            item.addEventListener('mouseover', () => {
                this.onMenuItemOver(item)
            })

            item.addEventListener('mouseout', () => {
                this.onMenuItemOut(item)
            })
        })
    }

    onMenuItemOver(item){
        if (!this.isDesktopMenu) {
            return
        }

        item.classList.add('menu-item--mouse-over')

        if (item.classList.contains('menu-item-has-children')) {
            this.element.classList.add('main-navigation--sub-menu-hover')

            const subMenu = item.querySelector('.sub-menu')
            this.setSubMenuBackgroundHeightByElement(subMenu)
        }
    }

    setSubMenuBackgroundHeightByElement(element, skipAnimation = false){
        if (skipAnimation) {
            this.subMenuBackground.classList.remove('main-navigation__sub-menu-background--animated')
        }

        this.subMenuBackground.style.height = `${element.offsetHeight}px`

        if (skipAnimation) {
            requestAnimationFrame(() => {
                this.subMenuBackground.classList.add('main-navigation__sub-menu-background--animated')
            })
        }
    }

    onMenuItemOut(item){
        item.classList.remove('menu-item--mouse-over')
        this.element.classList.remove('main-navigation--sub-menu-hover')
    }

    onOverlayMouseOver(){
        if (!this.isDesktopMenu) {
            return
        }

        this.element.classList.add('main-navigation--show-background')
        this.element.classList.remove('main-navigation--white-text')
    }

    onSubMenuArrowClick(subMenuItem, depth){
        this.closeAllSubMenuItemsByDepth(depth, subMenuItem)

        // when parent item gets closed also close nested items
        if (depth <= 2) {
            this.closeAllSubMenuItemsByDepth(3, subMenuItem)
        }

        subMenuItem.classList.toggle('menu-item--expanded')

        if (this.isDesktopMenu && depth === 3) {
            console.log('onSubMenuArrowClick | this.isDesktopMenu: ', this.isDesktopMenu)
            requestAnimationFrame(() => {
                const subMenu = subMenuItem.parentElement
                this.setSubMenuBackgroundHeightByElement(subMenu, true)
            })
        }
    }

    getSubMenuItemsByDepth(depth){
        if (depth === 2) {
            return this.subMenuItems
        }
        else if (depth === 3) {
            return this.subSubMenuItems
        }
    }

    closeAllSubMenuItemsByDepth(depth = 2, skipItem = null){
        const items = this.getSubMenuItemsByDepth(depth)
        if (!items) return

        items.forEach((item) => {
            if (item === skipItem) return
            item.classList.remove('menu-item--expanded')
        })
    }

    onNavigationMouseOut(event){
        if (!this.isDesktopMenu) {
            return
        }

        if (event.relatedTarget && event.relatedTarget.closest('.main-navigation')) {
            return
        }

        this.element.classList.remove('main-navigation--show-background')
        this.element.classList.add('main-navigation--white-text')

        this.updateAppearance()
    }

    findHeaderGallery(){
        const contentWrapper = document.body.querySelector('.entry-content')
        const firstLayoutContainer = contentWrapper.firstElementChild

        if (firstLayoutContainer) {
            //
            const hasOuterMarginNone = firstLayoutContainer.classList.contains('layout-grid--outer-margin-none')
            const firstColumn = firstLayoutContainer.querySelector('.layout-column')

            if (hasOuterMarginNone && firstColumn) {
                const firstBlock = firstColumn.firstElementChild
                this.isFirstBlockHeaderGallery = firstBlock.classList.contains('wp-block-dbec-core-header-gallery')
            }
        }
    }

    addScrollListener(){
        window.addEventListener('scroll', this.onScroll)
    }

    addResizeListener(){
        window.addEventListener('resize', this.onResize)
    }

    onScrollThrottled(){
        this.updateAppearance()
    }

    updateAppearance(){
        const scrolledBeyondThreshold = this.getScrolledBeyondThreshold()
        // const mobileActive = this.element.querySelector('.main-navigation__overlay--opened')
        const setWhiteText = this.isFirstBlockHeaderGallery && !scrolledBeyondThreshold
        const showBackground = scrolledBeyondThreshold //  || mobileActive

        if (setWhiteText) {
            this.element.classList.add('main-navigation--white-text')
        }
        else {
            this.element.classList.remove('main-navigation--white-text')
        }

        if (showBackground) {
            this.element.classList.add('main-navigation--show-background')
            this.element.classList.remove('main-navigation--white-text')
        }
        else {
            this.element.classList.remove('main-navigation--show-background')
        }
    }

    enableAnimations(){
        // next render tick
        requestAnimationFrame(() => {
            this.element.classList.add('main-navigation--animations-enabled')
        })
    }

    getScrolledBeyondThreshold(){
        const windowScrollY = window.pageYOffset || document.documentElement.scrollTop
        return windowScrollY >= 5
    }

    getShouldShowBackground(){
        return !this.isFirstBlockHeaderGallery || this.getScrolledBeyondThreshold()
    }

    onResizeDebounced(){
        this.updateActualMediaQuery()

        if (this.actualMediaQuery > screenSizes.medium) {
            this.setOverlayState(false)
        }
    }

    updateActualMediaQuery(){
        this.actualMediaQuery = getMediaQueryByWidth(window.innerWidth)
    }

    openOverlayClicked(){
        this.toggleOverlayState()
        this.toggleOverlayClass()
        this.updateAppearance()
    }

    closeOverlayClicked(){
        this.closeAllSubMenuItemsByDepth()
        this.toggleOverlayState()
        this.toggleOverlayClass()
        this.updateAppearance()
    }

    setOverlayState(isOpened){
        this.isOverlayOpened = isOpened
    }

    toggleOverlayState(){
        if(this.isOverlayOpened) {
            this.setOverlayState(false)
            this.overlay.classList.add('main-navigation__overlay--opened')
        }
        else {
            this.setOverlayState(true)
            this.overlay.classList.remove('main-navigation__overlay--opened')
        }
    }


    toggleOverlayClass(){
        this.overlay.classList.toggle('main-navigation__overlay--opened', this.isOverlayOpened)
    }
}
