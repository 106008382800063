export const loadBlock = async (chunk, selector, container, options = {}) => {
    const blockDomInstances = (container || document.body).querySelectorAll(selector)
    const blockInstances = []

    if (Array.from(blockDomInstances).length > 0) {
        const blockModule = await chunk()

        Array.from(blockDomInstances).forEach((element, index) => {
            const blockInstance = new blockModule.default(element, container, index, options)
            blockInstances.push(blockInstance)
        })
    }

    return blockInstances
}
