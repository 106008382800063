import BaseComponent from '@bahn-wordpress/common/src/js/blocks/base-component'
import eventEmitter from '@bahn-wordpress/common/src/js/utils/event-emitter'
import * as events from '@bahn-wordpress/common/src/js/utils/events'

export default class ImageReferences extends BaseComponent {
    constructor(...args){
        super(...args)

        this.references = []
        this.index = 0
        this.output = ''
        this.outputOpened = false
        this.moduleImageReferences = null
        this.containerOutput = null

        this.clickHandlerReference = this.onReferenceClicked.bind(this)

        if(this.element) {
            this.init()
        }
    }

    init(){
        this.moduleImageReferences = document.body.querySelector('.image-references')
        this.containerOutput = this.moduleImageReferences.querySelector('.image-references__output')

        if (this.moduleImageReferences) {
            this.initModuleBehaviour()
            this.addEventEmitterListener()
            this.delayedInit()
        }
    }

    initModuleBehaviour(){
        const buttonToggleOutput = this.moduleImageReferences.querySelector('.image-references__button-toggle')
        buttonToggleOutput.addEventListener('click', this.onButtonToggleClicked.bind(this))
    }

    addEventEmitterListener(){
        eventEmitter.on(events.listItemsLoaded, this.onListItemsLoaded.bind(this))
    }

    onListItemsLoaded(){
        this.removeClickListeners()
        this.resetReferences()
        this.findReferences()
        this.fillReferences()
        this.renderOutput()
    }

    onButtonToggleClicked(){
        this.outputOpened = !this.outputOpened
        this.updateOutputOpenedClass()
    }

    updateOutputOpenedClass(){
        if (this.outputOpened) {
            this.moduleImageReferences.classList.add('image-references--opened')
        }
        else {
            this.moduleImageReferences.classList.remove('image-references--opened')
        }
    }

    delayedInit(){
        this.findReferences()
        this.fillReferences()
        this.renderOutput()
    }

    resetReferences(){
        this.references = []
        this.index = 0
        this.output = ''
    }

    findReferences(){
        this.references = this.element.querySelectorAll('.label-reference')
    }

    fillReferences(){
        Array.from(this.references).forEach(this.fillReference.bind(this))
    }

    renderOutput(){
        if (this.index > 0) {
            this.containerOutput.innerHTML = this.output
            this.moduleImageReferences.classList.remove('image-references--hidden')
        }
        else {
            this.moduleImageReferences.classList.add('image-references--hidden')
        }
    }

    removeClickListeners(){
        Array.from(this.references).forEach((reference) => {
            reference.removeEventListener('click', this.clickHandlerReference)
        })
    }

    fillReference(reference){
        const referenceText = reference.getAttribute('data-image-reference')

        if (referenceText) {
            this.index += 1
            this.output += `<span>[${this.index}]</span> ${referenceText} `
            reference.innerHTML = this.index
            reference.classList.add('label-reference--filled')

            reference.addEventListener('click', this.clickHandlerReference)
        }
    }

    onReferenceClicked(event){
        event.preventDefault()
        this.outputOpened = true
        this.updateOutputOpenedClass()
        this.containerOutput.scrollIntoView({ behavior: 'smooth' })
    }
}
