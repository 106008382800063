import { mediaQueries } from './media-queries'

export const getMediaQueryByWidth = (width = window.innerWidth) => {
    for(const mediaQuery of mediaQueries) {
        if (width < mediaQuery.width) {
            return mediaQuery.name
        }
    }
}

